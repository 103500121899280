import React, {useState} from 'react';
import './App.css';
import { Avatar, Box, BoxProps, Button, Card, CardBody, CardFooter, CardHeader, DropButton, Grid, Text } from 'grommet';
import { Github,  Linkedin, Qr, Share, } from 'grommet-icons';
import { ReactComponent as GreenroomIcon } from './greenroom-robotics-icon-green.svg';
import { LinkPreview, LinkPreviewProps } from '@dhaiwat10/react-link-preview';
import { Popup } from 'semantic-ui-react'
import  DisplayPicture from './dp.jpeg'
import { Email } from "react-obfuscate-email";
import {QRCodeSVG} from 'qrcode.react';

const darkModeColor = (level?: number) => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
    if (level){
      return `dark-${level}`
    }
    else{
      return 'black'
    }
  }

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches){
  if (level){
    return `light-${level}`
  }
  else{
    return 'white'
  }
}
}

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

interface PreviewIconProps {
  icon: JSX.Element
  previewLinkProps: LinkPreviewProps
}

const PreviewStyle = {
  width: '40vh',
  height: '30vh',
  openInNewTab: true,
  // fallbackImageSrc: "string",
  // showPlaceholderIfNoImage: true,
}

const PreviewIcon = (props: PreviewIconProps) => {
  console.log("Showing Modal")
  const newStyle: LinkPreviewProps = {
    ...PreviewStyle,
    ...props.previewLinkProps
  }
  return (
        <Button
          icon={props.icon}
          onClick={() => openInNewTab(props.previewLinkProps.url)}
          hoverIndicator
        />
  )

}

interface CardBoxProps {
  text?: string
  element?: string
  cardBoxStyling?: BoxProps
}

const CardBoxStyle: BoxProps = {
  border: { color: 'green', size: 'small' },
  pad: "xsmall",
  flex: "grow",
  width: "20vh",
}


function App() {
  return (
    <div className="App">
      {/* <FrontHeader /> */}
      <Box
        direction="row"
        // border={{ color: 'green', size: 'large' }}
        pad="medium"
        justify='center'
        fill={true}
        align='center'
        style={{ height: "100vh" }}
        // round='50px'
        background={darkModeColor()}>
        <Card height="33.75vh" width="60vh" background={darkModeColor(1)} className='card-background' border={{ color: 'green', size: 'small' }}>
          <CardBody>
          <Box
            gap="small"
            fill={true}
            pad="small"
            direction='row'>
              <Box
              direction='column'
              width="60%"
              pad="medium"
              gap='medium'>              
                <Box gap='xxsmall'>
                <Box className='card-header'>
                  Nathan Edwards
                </Box>
                <Box className='subtitle'>
                  Sydney / Australia
                </Box>
                </Box>
                <Box gap='small' className='b1' justify='end' alignContent='end' style={{"paddingTop": "20px"}}>
                    <p>Greenroom Robotics</p>
                    <Email email="nathan.edwards@outlook.com.au" >nathan.edwards@outlook.com.au</Email>
                    <Email email="nathan.edwards@greenroomrobotics.com" >nathan.edwards@greenroomrobotics.com</Email>
                </Box>
              </Box>
              <Box
              direction='column'
              width="40%"
              pad="medium"
              justify='start'
              align='center'>
              <Box>
                <Avatar size='150px' src={DisplayPicture} animation="fadeIn" width="50px"/>
              </Box>
              </Box>
          </Box>
          </CardBody>
          <CardFooter pad={{ horizontal: "small" }} background={darkModeColor(2)} justify='end'>
            <PreviewIcon icon={<Github color={darkModeColor() == 'black' ? 'white' : 'black'} />} previewLinkProps={{
              url: "https://github.com/natrad100",
            }} />
            <PreviewIcon icon={<Linkedin color={darkModeColor() == 'black' ? 'white' : 'black'} />} previewLinkProps={{
              url: "https://www.linkedin.com/in/nathan-ad-edwards",
            }} />
            <PreviewIcon
              icon={<GreenroomIcon className='logo' />}
              previewLinkProps={{
                url: "https://greenroomrobotics.com",
                fallbackImageSrc: "https://greenroomrobotics.com/assets/blog/USV_UI/CHART_UI.png",
              }} />
          </CardFooter>
        </Card>
      </Box>
    </div>
  );
}

export default App;
